#all-courses-component .margin-15 {
    margin: 15px
}

#all-courses-component .course-image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 200px;
    text-align: center
}

#all-courses-component .course-name {
    padding: 10px;
    text-align: left;
    text-transform: capitalize; 
    font-weight: bold; 

}

