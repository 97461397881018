#instructor-profile {
    margin-top: 20px;
}
#instructor-profile .course-image-container {
    border: 1px solid #c1d5e0;
    box-shadow: var(--shadow);
    /*box-shadow: 0 2px 20px rgb(131 131 131);*/
}

#instructor-profile .course-image {
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
    border-bottom: 1px solid #dedede;
    /*box-shadow: rgba(131 131 131, 0.15) 0px 48px 100px 0px;*/
    width: 100%;
    height: 200px;
    text-align: center
}


#instructor-profile .course-name {
    padding: 10px;
    text-align: left;
    text-transform: capitalize;
    font-weight: bold;
    font-size: var(--heading-4);

}