#header .headerImage {
    background-color: var(--primary-color);
    height: 50px;
    min-height: 50px;
}
#header .margin-15 {
    margin: 15px
}

#header {
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #f4f5f7;
    margin-bottom: 10px;
    /*border-bottom: 1px solid #dedede;*/

}

#header a {
    color: var(--primary-font-color);
    /*font-weight: bold;*/
    text-transform: uppercase;
    font-size: 15px;
}

#header .active-link {
    color: var(--primary-color) !important
}

#header .logout-link {
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: 15px;
    text-transform: uppercase
}

#header .logout-link:hover {

    color: var(--primary-color);

}

#header .dropdown-toggle {
    padding: 0px !important;
    text-transform: uppercase;
    color: var(--primary-font-color);
    background: none !important;
    font-size: 15px;
}

#header .dropdown-toggle-menu {
    border: none;
    margin-top: 14px;
    margin-left: -24px;
    border-radius: 0px;
    width: 250px
}

#header .dropdown-toggle-menu a {
    font-weight: bold;
    text-transform: capitalize;
    padding:  10px 30px 10px 20px;
}

#header .header-nav-item {
    flex: 1;
    border-right: 1px solid #e5e7e9;
    padding: 25px 15px 15px 15px;
}

#header .header-nav-item:hover {
    flex: 1;
    border-right: 1px solid #e5e7e9;
    padding: 25px 15px 15px 15px;
    border-bottom: 2px solid var(--primary-color);
}


