.invalid-feedback-custom {
    /*display: none;*/
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}

.react-select__value-container {
    border-width: 1px 0px 1px 1px;
    border-color: #dc3545;
    border-style: solid;
}

.react-select__indicator {
    border: 1px solid #dc3545 !important
}