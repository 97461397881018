#forgot-password {
    min-height: 700px;
}

#forgot-password .forgot-box {
    /*width: 90%;*/
    /*max-width: 400px;*/
    border-radius: 5px;
    background-color: white;
    border: 1px solid #dedede;
    padding: 40px 80px 60px;
    box-shadow: var(--shadow);
}

#forgot-password .small-text {
    font-size: 12px;
    color: rgb(136, 136, 136)
}


#forgot-password .email-input-box {
    border-right: none ;
    border-left: none;
    border-top: none;
    border-radius: 0px;
    border-bottom: 1px solid black;
}

#forgot-password .email-input-box:focus {
    box-shadow: none;
    border: 1px solid black;
    border-radius: .25em;
}