:root {
  --primary-color: #A753AC ;
  --primary-font-color: #3c4852;
  --shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  --heading-1: 48px;
  --heading-2: 32px;
  --heading-3: 24px;
  --heading-4: 20px;
  --content: 16px
}

body {
  color: var(--primary-font-color);
  font-family: '"Lato",sans-serif', sans-serif;
  background: #fafafa !important;

}

/*.container {*/
/*  width: 1170px !important*/
/*}*/

a {
  text-decoration: none ;
  color: var(--primary-font-color) ;
}

a:hover {
  color: var(--primary-color) !important
  /*text-decoration: underline;*/
  /*color: black;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  background: var(--primary-color) !important;
  border: none !important;
}

.tox-editor-container button {
  background: none !important;
}

.accordion-button {
  background: #fff !important;
  font-size: 24px !important;
  font-weight: bold !important
}

.accordion-button:not(.collapsed) {
  color: #3c4852 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
