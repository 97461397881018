#chapter-details .lesson-active {
    text-decoration: none;
    color: var(--primary-color)
}

#chapter-details .lesson-inactive {
    /*padding: 0 10px 10px 0;*/
    text-decoration: none;
}

#chapter-details .chapter {
    font-weight: bold;
    padding: 10px 0 10px 0
}

#chapter-details .course-name {
    border-bottom: 1px solid #e3e3e3;
    padding: 20px 0 20px 0;
    text-align: left;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 18px
}

#chapter-details .index-container {
    border: 1px solid #e3e3e3;
    border-radius: 5px
}

#chapter-details .topic-complete {
    cursor: pointer;
    font-size: 25px;
    color: green
}

#chapter-details .topic-incomplete {
    cursor: pointer;
    font-size: 25px;
    color: var(--primary-color)
}

