#category-details-component .margin-15 {
    margin: 15px
}

#category-details-component .course-image {
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
    border-bottom: 1px solid #dedede;
    /*box-shadow: rgba(131 131 131, 0.15) 0px 48px 100px 0px;*/
    width: 100%;
    height: 200px;
    text-align: center
}

#category-details-component .course-name {
    padding: 10px;
    text-align: left;
    text-transform: capitalize; 
    font-weight: bold;
    font-size: var(--heading-4);

}

#category-details-component .course-image-container {
    border: 1px solid #c1d5e0;
    box-shadow: var(--shadow);
    /*box-shadow: 0 2px 20px rgb(131 131 131);*/
}

#category-details-component .category-image-container {
    box-shadow: var(--shadow);
}

#category-details-component .category-image {
    height: 200px;
    width: 100%;
}

#category-details-component .category-heading {
    font-weight: bold;
    text-transform: capitalize;
    font-size: var(--heading-4);
}

#category-details-component .category-excerpt {
    text-align: justify;
    font-size: var(--content);
}

#category-details-component .course-instructor-name {
    flex: 18;
    text-transform: capitalize;
    font-weight: bold;
    font-size: var(--content);
}

