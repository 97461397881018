#home-component .course-name {
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    /*color: black*/
}

#home-component .course-image {
    /*border-radius: 10px;*/
    width: 100%;
    height: 200px;
    text-align: center
}

#home-component .course-image-container {
    box-shadow: var(--shadow);
    textAlign: center
}

#home-component .categories-heading {
    font-size: var(--heading-4);
    font-weight: bold
}

#home-component .about-us-content {
    font-size: var(--content);
    text-align: justify;
}