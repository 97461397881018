#course-details .margin-15 {
    margin: 15px
}

#course-details .accordion-button {
    font-size: var(--heading-4) !important;
    font-weight: normal !important;
    color: var(--primary-font-color)
}

#course-details .course-name {
    font-size: var(--heading-3);
    /*font-weight: bold;*/
    text-transform: capitalize
}

#course-details .about {
    font-size: var(--heading-3);
    margin-bottom: 10px
}

#course-details .about-this-module {
    font-size: var(--heading-3);
    /*margin-bottom: 10px*/
}

#course-details .topics-covered {
    font-size: var(--heading-4);
    margin-bottom: 10px
}

