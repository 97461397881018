#footer {

    background-color: var(--primary-color);
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px
}

#footer .margin-15 {
    margin: 15px
}

#footer .footer-link {
    color: black
}

#footer .contact-us {
    color: black;
}

