#login {
    min-height: 700px;
}

#login .login-box {
    /*margin: 10px;*/
    /*width: 90%;*/
    /*max-width: 400px;*/
    border-radius: 5px;
    background-color: white;
    border: 1px solid #dedede;
    padding: 40px;
    box-shadow: var(--shadow);
}

#login .forgot-password-link {
    text-align: left;
    font-size: 12px;
}

#login .forgot-password-link a {
    color: rgb(2, 134, 248);
}

#login .signup-link {
    font-size: 15px;
    margin-top: 20px;
    text-align: left
}

#login .signup-link a {
    color: rgb(2, 134, 248);
}

#login .login-button {
    /*text-align: left;*/
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}

#login .email-input-box {
    border-right: none ;
    border-left: none;
    border-top: none;
    border-radius: 0px;
    border-bottom: 1px solid black;
}

#login .email-input-box:focus {
    box-shadow: none;
    border: 1px solid black;
    border-radius: .25em;
}
